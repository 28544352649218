import { ContainerXl } from '/features/buildingBlocks/Container'
import { HeadingLg, HeadingXs } from '/features/buildingBlocks/Heading'
import ImageCropped from '/features/buildingBlocks/ImageCropped.universal'
import ImageCover from '/features/buildingBlocks/ImageCover.universal'
import { useMediaQuery } from '@kaliber/use-media-query'
import { animated, useTrail } from 'react-spring'
import { useTranslate } from '/machinery/I18n'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Hero.css'

export function Hero({ title, category, introduction, image }) {
  const isMobile = useMediaQuery(mediaStyles.viewportMd) === false
  const { __ } = useTranslate()

  const [[firstStyle, secondStyle, thirdStyle, forthStyle]] = useTrail(
    4,
    (i) => ({
      from: { opacity: 0, y: 15 },
      to: { opacity: 1, y: 0 },
    })
  )

  const mobileOrder = [secondStyle, thirdStyle, forthStyle, firstStyle]
  const desktopOrder = [firstStyle, secondStyle, thirdStyle, forthStyle]
  const [categoryStyle, titleStyle, introductionStyle, imageStyle] = isMobile ? mobileOrder : desktopOrder

  const sizes = `
    (min-width: ${mediaStyles.breakpointLg}) calc((100vw - var(--container-padding)) / 2),
    calc(100vw - var(--container-padding))
  `

  return (
    <ContainerXl>
      <header className={styles.component}>
        <animated.span style={categoryStyle} className={cx(styles.type, styles.labelLayout)}>
          {__`article-category-${category ?? 'article'}`}
        </animated.span>

        <animated.div style={titleStyle} className={styles.titleLayout}>
          <HeadingLg h='1' {...{ title }} />
        </animated.div>

        <animated.div style={introductionStyle} className={styles.introductionLayout}>
          <HeadingXs h='2' title={introduction} />
        </animated.div>

        <animated.div style={imageStyle} className={styles.imageLayout}>
          {isMobile
            ? <ImageCropped aspectRatio={1 / 1} {...{ sizes, image }} />
            : <ImageCover aspectRatio={5 / 7} {...{ sizes, image }} />
          }
        </animated.div>
      </header>
    </ContainerXl>
  )
}
